<template>
  <v-app>
    <MenuComponent :isAuth="isAuth" />
    <v-main>
      <v-container v-if="alert">
        <v-alert dense outlined :type="getAlertType()">
          <template v-if="'danger' in alert">{{ alert.danger }}</template>
          <template v-if="'success' in alert">{{ alert.success }}</template>
        </v-alert>
      </v-container>
      <template v-if="isAuth">
        <router-view />
      </template>
      <template v-else>
        <AuthComponent />
      </template>
    </v-main>
    <UpdateComponent />
    <CountersComponent />
  </v-app>
</template>

<script>
import MenuComponent from "./components/MenuComponent";
import UpdateComponent from "./components/UpdateComponent";
import AuthComponent from "./components/AuthComponent";
import CountersComponent from "./components/CountersComponent";
import { mapState } from "vuex";
import api from "./api";

export default {
  name: "App",
  computed: {
    ...mapState({
      token: (state) => state.token,
      version: (state) => state.version,
      alert: (state) => state.alert,
    }),
    isAuth() {
      return this.token ? true : false;
    },
  },
  watch: {
    token() {
      localStorage.setItem("token", this.token);
      this.sendDevice();
    },
    alert() {
      if (this.alert) {
        let _self = this;
        setTimeout(() => {
          _self.removeAlert();
        }, 5000);
      }
    },
  },
  created() {
    this.initErrorReporting();
    this.uriSearchParams();
    this.sendDevice();
  },
  methods: {
    api(data, resolve = false) {
      api(this, data, "/", resolve);
    },
    initErrorReporting() {
      let _self = this;
      window.addEventListener("error", function (event) {
        let obj = {
          mesage: event.message,
          stack: event.error.stack,
        };
        let data = new FormData();
        data.set("type", "saveError");
        data.set("error", JSON.stringify(obj));
        _self.api(data);
      });
    },
    uriSearchParams() {
      let paramsString = location.search;
      let searchParams = new URLSearchParams(paramsString);
      for (let p of searchParams) {
        if (p[0] == "token") {
          this.$store.dispatch("set", { token: p[1] });
        }
      }
    },
    sendDevice() {
      if (this.token) {
        let data = new FormData();
        data.set("device[user_agent]", navigator.userAgent);
        data.set(
          "device[browser_type]",
          Number(window.matchMedia("(display-mode: standalone)").matches)
        );
        data.set("device[version]", this.version);
        this.$store.dispatch("api", data);
      }
    },
    getAlertType() {
      let type;
      if ("danger" in this.alert) {
        type = "error";
      } else if ("warning" in this.alert) {
        type = "warning";
      } else if ("success" in this.alert) {
        type = "success";
      }
      return type;
    },
    removeAlert() {
      let data = new FormData();
      data.set("type", "removeAlert");
      this.$store.dispatch("api", data);
    },
  },
  components: {
    MenuComponent,
    AuthComponent,
    UpdateComponent,
    CountersComponent,
  },
};
</script>
