<template>
  <div v-if="driver" class="home">
    <v-container>
      <v-alert v-if="message" type="success">{{ message }}</v-alert>
      <h4>Данные профиля:</h4>
      <hr />
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Ф.И.О.</td>
              <td>{{ driver.full_name }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{{ driver.ofert_email }}</td>
            </tr>
            <tr>
              <td>Номер карты</td>
              <td>
                <span v-if="!view_pay">
                  {{ driver.ofert_pay_hide }}
                  <v-icon small class="view_pay" @click="view_pay = !view_pay"
                    >mdi-eye</v-icon
                  >
                </span>
                <span v-else>
                  <template v-if="pay_img">
                    {{ getPayNum(pay_img) }}
                    <v-icon small class="view_pay" @click="view_pay = !view_pay"
                      >mdi-eye-off-outline</v-icon
                    >
                  </template>
                  <template v-else>
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </template>
                </span>
              </td>
            </tr>
            <tr>
              <td>Баланс</td>
              <td>{{ driver.balance | amountNormal }}</td>
            </tr>
            <tr>
              <td>Минимальная сумма для списания</td>
              <td>{{ driver.min_payment | amountNormal }}</td>
            </tr>
            <tr>
              <td>Минимальный остаток</td>
              <td>{{ driver.min_balance | amountNormal }}</td>
            </tr>
            <tr>
              <td>Остаток дневного лимита</td>
              <td>{{ driver.limit_today | amountNormal }}</td>
            </tr>
            <tr>
              <td>Можно списать</td>
              <td>{{ getMaxOffer() | amountNormal }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card>
        <v-card-text>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="7">
                <v-text-field
                  v-model="amount"
                  :rules="beetweenAmountRule"
                  label="Сумма к выводу на карту"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-btn
                  :disabled="!valid"
                  block
                  color="success"
                  class="mr-4"
                  elevation="2"
                  @click="addTransaction()"
                >
                  Списать
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-simple-table v-if="amount > 0" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Комиссия:</th>
                  <th>Процент:</th>
                  <th>К получению:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ getFactCommission() | amountNormal }}</td>
                  <td
                    class="white--text"
                    :class="
                      getFactProcent() <= qiwiParams.procent
                        ? 'success'
                        : 'error'
                    "
                  >
                    {{ getFactProcent() | amountNormalTwo }}
                  </td>
                  <td>{{ getFactAmount() | amountNormal }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <template v-if="qiwiParams">
            <hr />
            <div>
              * Комиссия: {{ qiwiParams.procent | amountNormal }}%, но не менее
              {{ qiwiParams.min | amountNormal }} рублей.
            </div>
            <div>
              * Дневной лимит на вывод:
              {{ qiwiParams.limit | amountNormal }} рублей.
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import filters from "../filters";
import api from "../api";

export default {
  name: "Home",
  data() {
    return {
      driver: null,
      qiwiParams: null,
      pay_img: null,
      valid: false,
      amount: 0,
      message: null,
      view_pay: false,
      start_transaction: false,
    };
  },
  computed: {
    beetweenAmountRule() {
      let arr = [];
      let accept = false;
      if (
        Number(this.amount) <= Number(this.getMaxOffer()) &&
        Number(this.amount) > Number(0)
      ) {
        accept = true;
      }
      const rule = (v) => accept || "Сумма вне диапозоне возможных значений.";
      arr.push(rule);
      return arr;
    },
  },
  watch: {
    message() {
      if (this.message) {
        let _self = this;
        setTimeout(() => {
          _self.message = null;
        }, 3000);
      }
    },
    view_pay() {
      if (this.view_pay) {
        let data = new FormData();
        data.set("type", "getPayImg");
        this.api(data);
      } else {
        this.pay_img = null;
      }
    },
    start_transaction(flag) {
      this.valid = !flag;
    },
  },
  created() {
    this.getInfo();
  },
  methods: {
    api(data, resolve = false) {
      api(this, data, "/", resolve);
    },
    getInfo() {
      let data = new FormData();
      data.set("type", "getInfo");
      this.api(data);
    },
    getMaxOffer() {
      let max = Number(this.driver.balance) - Number(this.driver.min_balance);
      if (Number(max) - Number(this.driver.min_payment) < 0) {
        return 0;
      }
      if (Number(this.driver.limit_today) < Number(max)) {
        let limit = Number(this.driver.limit_today);
        return limit > 0 ? limit : 0;
      }
      return max;
    },
    addTransaction() {
      if (this.start_transaction) {
        return;
      }
      this.start_transaction = true;
      let data = new FormData();
      data.set("type", "addTransaction");
      data.set("amount", this.amount);
      let _self = this;
      let promise = new Promise((resolve) => {
        window.resolve = resolve;
        _self.api(data, resolve);
      });
      promise.then((response) => {
        if (response.success) {
          _self.message = "Операция прошла успешно";
          _self.getInfo();
        }
        _self.start_transaction = false;
      });
    },
    getPayNum(text) {
      return atob(text);
    },
    getFactCommission() {
      let comission = Number(
        (this.amount / 100) * Number(this.qiwiParams.procent)
      );
      if (comission < Number(this.qiwiParams.min)) {
        comission = Number(this.qiwiParams.min);
      }
      return parseInt(comission);
    },
    getFactProcent() {
      return this.getFactCommission() / (Number(this.amount) / 100);
    },
    getFactAmount() {
      let amount = Number(this.amount) - this.getFactCommission();
      if (amount < 0) {
        amount = 0;
      }
      return amount;
    },
  },
  filters: filters,
};
</script>

<style lang="less">
.view_pay {
  cursor: pointer;
}
</style>
