<template>
  <div id="dtp">
    <v-container class="text-center">
      <v-btn @click="addDtp()">Сообщить о ДТП</v-btn>
    </v-container>
  </div>
</template>

<script>
import api from "../api";

export default {
  name: "Dtp",
  data() {
    return {
      coords: '',
      coordsSuccess: false,
    };
  },
  watch: {
    coordsSuccess(flag) {
      if (flag) {
        this.acceptDtp();
        this.coordsSuccess = false;
      }
    },
  },
  methods: {
    api(data, resolve = false) {
      api(this, data, "", resolve);
    },
    addDtp() {
      let _self = this;
      navigator.geolocation.getCurrentPosition(
        (success) => {
          _self.coords = [success.coords.latitude, success.coords.longitude];
          _self.coordsSuccess = true;
        },
        (error) => {
          _self.coordsSuccess = true;
        },
        {
          enableHighAccuracy: true,
        }
      );
    },
    acceptDtp() {
      if (!confirm("Вы подтверждаете отправку данных о ДТП?")) {
        return;
      }
      let data = new FormData();
      data.set("type", "acceptDtp");
      if(this.coords){
        data.set("coords", JSON.stringify(this.coords));
      }
      this.api(data);
    },
  },
};
</script>

<style>
</style>