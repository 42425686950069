import moment from "moment";

let filters = {
    amountNormal(val) {
        if (!val) {
            return 0;
        }
        return Number(val).toLocaleString('ru');
    },
    amountNormalTwo(val) {
        if (!val) {
            return 0;
        }
        return Number(val).toFixed(2);
    },
    amountIntNormal(val) {
        if (!val) {
            return 0;
        }
        return parseInt(val).toLocaleString('ru');
    },
    dateTimeNormal(val) {
        if (!val) {
            return '';
        }
        return moment(val).format('DD.MM.YYYY HH:mm');
    },
    dateNormal(val) {
        if (!val) {
            return '';
        }
        return moment(val).format('DD.MM.YYYY');
    },
    payStatus(val) {
        let statuses = {
            '-1': 'Платеж не был зарегистрирован из-за временной ошибки',
            50: 'Платеж принят в обработку',
            52: 'Средства зачисляются',
            60: 'Платеж проведен',
            150: 'Платеж не принят',
            151: 'Ошибка авторизации платежа',
            160: 'Платеж не проведен или отменен'
        }
        if (val in statuses) {
            return statuses[val];
        }
        if (Number(val) >= 0 && Number(val) <= 49) {
            return 'Платеж принят, но ждет подтверждения со стороны системы QIWI Wallet';
        }
        if (Number(val) >= 50 && Number(val) <= 59) {
            return 'Платеж находится в проведении';
        }
        if (Number(val) > 100) {
            return 'Ошибка проведения платежа';
        }
    }
}

export default filters;