<template>
  <v-container class="potential">
    <v-dialog transition="dialog-bottom-transition" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn block color="primary" v-bind="attrs" v-on="on"
          >Добавить водителя</v-btn
        >
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark
            >Введите данные нового водителя</v-toolbar
          >
          <v-card-text>
            <v-form
              id="form-potential"
              @submit.prevent="addPotential($event.target)"
            >
              <v-container>
                <v-text-field
                  v-model="potential.name"
                  label="Имя водителя"
                  name="Potential[name]"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="potential.phone"
                  label="Телефон водителя"
                  name="Potential[phone]"
                  required
                ></v-text-field>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-space-between">
            <v-btn text @click="dialog.value = false">Закрыть</v-btn>
            <v-btn
              type="submit"
              form="form-potential"
              color="success"
              @click="dialog.value = false"
              >Сохранить</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <template v-for="driver in drivers">
      <v-card :key="driver.id">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Имя:</td>
                <td>{{ driver.name }}</td>
              </tr>
              <tr>
                <td>Телефон:</td>
                <td>{{ driver.phone }}</td>
              </tr>
              <tr>
                <td>Дата:</td>
                <td>{{ driver.date | dateTimeNormal}}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import api from "../api";
import filters from "../filters";

export default {
  name: "Potential",
  data() {
    return {
      drivers: [],
      potential: {
        name: "",
        phone: "",
      },
    };
  },
  created() {
    this.getPotential();
  },
  methods: {
    api(data, resolve = false) {
      api(this, data, "", resolve);
    },
    getPotential() {
      let data = new FormData();
      data.set("type", "getPotential");
      this.api(data);
    },
    addPotential(form) {
      let data = new FormData(form);
      data.set("type", "addPotential");
      let _self = this;
      let promise = new Promise((resolve) => {
        _self.api(data, resolve);
      });
      promise.then(() => {
        _self.getPotential();
      });
    },
  },
  filters: filters
};
</script>

<style lang="less">
    .v-main {
        background-color: #eee;
    }
    .v-card {
        margin-top: 20px;
    }
</style>