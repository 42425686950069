<template>
  <div class="packages">
    <v-container>
      <v-card>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Название</th>
                <th>Подключен</th>
                <th>Кол-во заказов</th>
                <th>бонус</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in packages">
                <tr :key="item.id" :class="{ success: !!Number(item.active) }">
                  <td>{{ item.name }}</td>
                  <td>{{ !!Number(item.active) ? "Да" : "Нет" }}</td>
                  <td>{{ item.count_orders }}</td>
                  <td>{{ item.bonus | amountNormal }}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import filters from "../filters";

export default {
  name: "packages",
  data() {
    return {
      packages: [],
    };
  },
  created() {
    this.getPackages();
  },
  methods: {
    api(data, resolve) {
      api(this, data, "", resolve);
    },
    getPackages() {
      let data = new FormData();
      data.set("type", "getPackages");
      this.api(data);
    },
  },
  filters: filters,
};
</script>

<style></style>
