<template>
  <v-container>
    <v-alert v-if="error" dense outlined type="error">
      {{ error }}
    </v-alert>
    <v-form v-if="step == 1" v-model="validPhone">
      <v-text-field
        v-model="phoneBlack"
        :rules="phoneRules"
        label="Введите телефон указанный в Таксиметре"
        required
      ></v-text-field>
      <v-btn
        :disabled="!validPhone"
        color="success"
        class="mr-4"
        @click="checkPhone()"
      >
        Подтвердить
      </v-btn>
    </v-form>
    <v-form v-if="step == 2" v-model="validSmsCode">
      <h4>
        На номер телефона <b class="primary--text">{{ phone }}</b> отправлен SMS
        с кодом подтверждения
      </h4>
      <v-text-field
        v-model="smsCode"
        :rules="smsRules"
        label="Введите код из смс"
        required
      ></v-text-field>

      <v-btn
        :disabled="!validSmsCode"
        color="success"
        class="mr-4"
        elevation="2"
        @click="checkCode()"
      >
        Подтвердить
      </v-btn>
      <v-btn
        :disabled="!!timer"
        elevation="2"
        class="mr-4"
        @click="checkPhone()"
        >Выслать код повторно {{ timer }}</v-btn
      >
      <v-btn elevation="2" class="mr-4" @click="step = 1">изменить номер</v-btn>
    </v-form>
    <v-form v-if="step == 3" v-model="validEmail">
      <v-text-field
        v-model="ofert_email"
        :rules="emailRules"
        label="Введите Ваш email"
        required
      ></v-text-field>
      <template v-if="validEmail">
        <h4>Для того чтобы продолжить, необходимо принять оферту:</h4>
        <div v-html="ofertReplacer"></div>
        <v-btn
          :disabled="!validEmail || !ofert"
          color="success"
          class="mr-4"
          @click="acceptOfert()"
        >
          Принять
        </v-btn>
      </template>
    </v-form>
    <v-form v-if="step == 4" v-model="validCart">
      <v-text-field
        v-model="ofert_pay"
        :rules="cartRules"
        label="Введите номер банковской карты, на которую будет осуществляться вывод средств."
        required
      ></v-text-field>
      <v-btn
        :disabled="!validCart"
        color="success"
        class="mr-4"
        @click="saveCart()"
      >
        Принять
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import moment from "moment";
import luhn from "luhn";

export default {
  name: "auth-component",
  data() {
    return {
      error: null,
      phone: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : null,
      phoneBlack: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : null,
      smsCode: null,
      validPhone: false,
      validSmsCode: false,
      ofert: localStorage.getItem("ofert")
        ? localStorage.getItem("ofert")
        : '',
      validEmail: false,
      ofert_email: null,
      ofert_pay: null,
      validCart: false,
      now: moment(),
      startPhoneCheckedDate: localStorage.getItem("startPhoneCheckedDate")
        ? localStorage.getItem("startPhoneCheckedDate")
        : null,
      successSmsCode: false,
      successPolitic: false,
      step: localStorage.getItem("step") ? localStorage.getItem("step") : 1,
      token_before: localStorage.getItem("token_before")
        ? localStorage.getItem("token_before")
        : null,
      full_name: localStorage.getItem("full_name")
        ? localStorage.getItem("full_name")
        : null,
    };
  },
  computed: {
    phoneRules() {
      let arr = [];
      let accept = this.normalPhone();
      const rule = (v) => accept || "Некорректный номер телефона.";
      arr.push(rule);
      return arr;
    },
    smsRules() {
      let arr = [];
      const rule = (v) => /[0-9]{4}/.test(v) || "В коде должно быть 4 цифры.";
      arr.push(rule);
      return arr;
    },
    emailRules() {
      let arr = [];
      const rule = (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "некорректный email";
      arr.push(rule);
      return arr;
    },
    cartRules() {
      let arr = [];
      let accept = this.luhnAlg();
      const rule = (v) => accept || "Некорректный номер карты.";
      arr.push(rule);
      return arr;
    },
    timer() {
      let timer = null;
      if (!this.startPhoneCheckedDate) {
        return timer;
      }
      let now = this.now;
      let checkTime = moment(this.startPhoneCheckedDate);
      if (checkTime.add(5, "minutes") > now) {
        let diff = parseInt(checkTime.diff(now) / 1000);
        let minutes = parseInt(diff / 60);
        let seconds = (diff % 60).toString();
        seconds = seconds.length > 1 ? seconds : "0" + seconds;
        timer = minutes + ":" + seconds;
      }
      return timer;
    },
    ofertReplacer(){
      let data = {
        phone: this.phone,
        email: this.ofert_email,
        date: moment().format('DD.MM.YYYY'),
        full_name: this.full_name
      }
      let content = this.ofert;
      for(let key in data){
        content = content.replaceAll('{' + key + '}', data[key]);
      }
      return content;
    }
  },
  watch: {
    step() {
      localStorage.setItem("step", this.step);
    },
    phone() {
      localStorage.setItem("phone", this.phone);
    },
    ofert(){
      localStorage.setItem("ofert", this.ofert);
    },
    startPhoneCheckedDate() {
      localStorage.setItem("startPhoneCheckedDate", this.startPhoneCheckedDate);
    },
    token_before() {
      localStorage.setItem("token_before", this.token_before);
    },
    full_name() {
      localStorage.setItem("full_name", this.full_name);
    },
    error() {
      if (this.error) {
        let _self = this;
        setTimeout(() => {
          _self.error = null;
        }, 3000);
      }
    },
  },
  created() {
    this.updateNow();
  },
  methods: {
    updateNow() {
      let _self = this;
      setInterval(() => {
        _self.now = moment();
      }, 1000);
    },
    checkPhone() {
      let data = new FormData();
      data.set("type", "checkPhone");
      data.set("phone", this.phone);
      let _self = this;
      let promise = new Promise((resolve) => {
        window.resolve = resolve;
        _self.$store.dispatch("api", data);
      });
      promise.then((response) => {
        if (response.success) {
          _self.startPhoneCheckedDate = moment().format("YYYY-MM-DD HH:mm:ss");
          _self.step = 2;
        } else {
          _self.error = response.error
            ? response.error
            : "Указанный номер телефона не найден. Обратитесь в диспетчерскую.";
          _self.step = 1;
        }
      });
    },
    checkCode() {
      let data = new FormData();
      data.set("type", "checkCode");
      data.set("code", this.smsCode);
      let _self = this;
      let promise = new Promise((resolve) => {
        window.resolve = resolve;
        _self.$store.dispatch("api", data);
      });
      promise.then((response) => {
        if (response.success) {
          _self.startPhoneCheckedDate = null;
          _self.token_before = response.token_before;
          _self.step = response.step;
          _self.ofert = response.ofert;
          _self.full_name = response.full_name;
        } else {
          _self.error = "Неверный код";
        }
      });
    },
    acceptOfert() {
      let data = new FormData();
      data.set("type", "acceptOfert");
      data.set("id", this.token_before);
      data.set("email", this.ofert_email);
      let _self = this;
      let promise = new Promise((resolve) => {
        window.resolve = resolve;
        _self.$store.dispatch("api", data);
      });
      promise.then((response) => {
        if (response.success) {
          _self.step = 4;
        }
      });
    },
    saveCart() {
      let data = new FormData();
      data.set("type", "saveCart");
      data.set("id", this.token_before);
      data.set("ofert_pay", this.ofert_pay);
      this.$store.dispatch("api", data);
    },
    normalPhone() {
      if (!this.phoneBlack) {
        return false;
      }
      let phone;
      let str = this.phoneBlack.replace(/\D+/g, "");
      let count = str.length;
      switch (count) {
        case 10:
          if (str[0] == "9") {
            str = "8" + str;
          } else {
            str = null;
          }
          break;
        case 11:
          if (str[0] == "7") {
            str = "8" + str.substr(1);
          } else {
            if (str[0] != "8") {
              str = null;
            }
          }
          break;
        default:
          str = null;
      }
      phone = str;
      if (!phone) {
        return false;
      }
      this.phone = phone;
      return true;
    },
    luhnAlg() {
      if (!this.ofert_pay || this.ofert_pay.length < 13) {
        return false;
      }
      return luhn.validate(this.ofert_pay);
    },
  },
};
</script>

<style></style>
