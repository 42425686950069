import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Transaction from '../views/Transaction.vue'
import Orders from '../views/Orders.vue'
import Packages from '../views/Packages.vue'
import Agents from '../views/Agents.vue'
import Dtp from '../views/Dtp.vue'
import Curator from '../views/Curator.vue'
import Potential from '../views/Potential.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/transaction',
        name: 'Transaction',
        component: Transaction
    },
    {
        path: '/orders',
        name: 'Orders',
        component: Orders
    },
    {
        path: '/packages',
        name: 'Packages',
        component: Packages
    },
    {
        path: '/agents',
        name: 'Agents',
        component: Agents
    },
    {
        path: '/dtp',
        name: 'Dtp',
        component: Dtp
    },
    {
        path: '/curator',
        name: 'Curator',
        component: Curator
    },
    {
        path: '/potential',
        name: 'Potential',
        component: Potential
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router