<template>
  <div class="curator">
    <v-container v-if="curator">
      <v-tabs centered>
        <v-tab @click="tab = 1">Водители</v-tab>
        <v-tab @click="tab = 2">Транзакции</v-tab>
      </v-tabs>
      <template v-if="tab == 1">
        <template v-for="(item, key) in curator.to_drivers">
          <v-card :key="key">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>№ П/П</td>
                    <td>{{ key + 1 }}</td>
                  </tr>
                  <tr>
                    <td>ФИО Новичка</td>
                    <td>{{ item.driver.full_name }}</td>
                  </tr>
                  <tr>
                    <td>Дата подключения</td>
                    <td>{{ item.date | dateNormal }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </template>
      </template>
      <template v-if="tab == 2">
        <template v-for="(item, key) in orders">
          <v-card :key="key">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Отчётная дата</td>
                    <td>{{ item.date | dateNormal }}</td>
                  </tr>
                  <tr>
                    <td>ФИО Новичка</td>
                    <td>{{ item.driver.full_name }}</td>
                  </tr>
                  <tr>
                    <td>Сумма</td>
                    <td class="green--text">+{{ item.amount | amountNormal }} &#8381;</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </template>
      </template>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import api from "../api";
import filters from "../filters";

export default {
  name: "Curator",
  data() {
    return {
      curator: null,
      orders: [],
      tab: 1,
    };
  },
  created() {
    this.getCurator();
  },
  methods: {
    api(data, resolve = false) {
      api(this, data, "", resolve);
    },
    getCurator() {
      let data = new FormData();
      data.set("type", "getCurator");
      this.api(data);
    },
    getOrderInfo(orders, key, format) {
      let amount = 0;
      let date_start = moment().startOf("isoWeek");
      for (let i in orders) {
        let order = orders[i];
        let date = moment(order.date);
        if (format == "last") {
          if (date < date_start) {
            amount += Number(order[key]);
          }
        } else if (format == "this") {
          if (date >= date_start) {
            amount += Number(order[key]);
          }
        }
      }
      return amount;
    },
  },
  filters: filters,
};
</script>

<style lang="less">
.curator {
  .v-card {
    margin-bottom: 20px;
    td:first-child {
      width: 350px;
    }
  }
  .v-slide-group__content {
    > div {
      width: 50%;
    }
  }
}
</style>