/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        /*ready() {
            console.log(
                'Приложение обслуживается из кеша сервисным работником.\n' +
                'Для получения дополнительной информации посетите https://goo.gl/AFskqB'
            )
        },*/
        registered(registration) {
            //console.log('Сервисный работник зарегистрирован.');
            setInterval(() => {
                registration.update();
                //console.log('Ищу обновления...');
            }, 1000 * 5);
        },
        /*cached() {
            console.log('Контент был кэширован для использования в автономном режиме.')
        },
        updatefound() {
            console.log('Новый контент загружается.')
        },*/
        updated(registration) {
            //console.log('Доступен новый контент; обновите его.');
            document.dispatchEvent(
                new CustomEvent('swUpdated', { detail: registration })
            );
        },
        /*offline() {
            console.log('Нет подключения к Интернету. Приложение работает в автономном режиме.')
        },
        error(error) {
            console.error('Ошибка при регистрации сервис-воркера:', error)
        }*/
    })
}