<template>
  <div class="transaction">
    <v-container>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Дата</th>
              <th>Сумма</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in transactions" :key="key">
              <td>{{item.date | dateTimeNormal}}</td>
              <td>{{item.amount | amountNormal}}</td>
              <td>{{item.status | payStatus}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import api from '../api';
import filters from "../filters";

export default {
  name: "transaction",
  data(){
    return {
      transactions: []
    }
  },
  created(){
    this.getTransactions();
  },
  methods: {
    api(data, resolve){
      api(this, data, '', resolve);
    },
    getTransactions(){
      let data = new FormData();
      data.set("type", "getTransactions");
      this.api(data);
    }
  },
  filters: filters
};
</script>
