<template>
  <div class="orders">
    <v-container>
      <div v-if="pages && 'totalCount' in pages" class="text-center">
        <v-pagination
          v-model="page"
          :length="Number(pages.totalCount)"
          :total-visible="8"
          circle
        ></v-pagination>
      </div>
      <div class="text-right button-update">
        <v-btn color="primary" class="right" @click="update_orders = true"
          >Обновить</v-btn
        >
      </div>
      <template v-for="order in orders">
        <v-card :key="order.id">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <template v-for="(value, key) in order">
                  <tr v-if="key in columns" :key="key">
                    <td>{{ columns[key] }}</td>
                    <td>{{ getValue(value, key) }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </template>
      <div v-if="pages && 'totalCount' in pages" class="text-center">
        <v-pagination
          v-model="page"
          :length="Number(pages.totalCount)"
          :total-visible="8"
          circle
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import api from "../api";

export default {
  name: "orders",
  data() {
    return {
      page: 1,
      orders: [],
      pages: null,
      columns: {
        id: "Хеш заказа",
        arrival_date: "Дата выполнения",
        company_name: "Компания",
        from_full_address_name: "Откуда",
        to_full_address_name: "Куда",
        commission_citymobil: "Комиссия CityMobil",
        commission_partner: "Комиссия Таксопарка",
        bill: "Сумма заказа",
        earning: "Заработок",
      },
      update_orders: false,
    };
  },
  watch: {
    page() {
      this.getOrders();
    },
    update_orders() {
      if (this.update_orders) {
        this.getOrders();
        this.update_orders = false;
      }
    },
  },
  created() {
    this.getOrders();
  },
  methods: {
    api(data, resolve) {
      api(this, data, "", resolve);
    },
    getOrders() {
      let data = new FormData();
      data.set("type", "getOrders");
      data.set("page", this.page);
      if (this.update_orders) {
        data.set("update_orders", true);
      }
      this.api(data);
    },
    getValue(value, key) {
      if (~key.indexOf("date")) {
        return moment(value).format("DD.MM.YYYY HH:mm");
      }
      return value;
    },
  },
};
</script>

<style lang="less">
.orders {
  background-color: #eee;
  .v-pagination {
    margin: 10px 0px 20px 0px;
  }
  .button-update {
    margin-bottom: 20px;
  }
  .v-card {
    margin-bottom: 50px;
  }
  table {
    tr {
      td:first-child {
        width: 200px;
      }
    }
  }
}
</style>
