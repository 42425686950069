import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        version: '1.02',
        host: 'https://park.volga-taxi.ru/api/driver/api',
        loader: false,
        token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
        alert: null,
        driver: null
    },
    mutations: {
        set(state, obj) {
            let key = Object.keys(obj)[0];
            state[key] = obj[key];
        }
    },
    actions: {
        api({ commit }, data) {
            commit("set", { loader: true });
            data.set('token', this.state.token);
            let xhr = new XMLHttpRequest();
            xhr.open("POST", this.state.host, true);
            xhr.responseType = 'json';
            xhr.send(data);
            xhr.addEventListener(
                "load",
                e => {
                    let xhrObj = e.target;
                    if (xhrObj.status == 200) {
                        let response = xhrObj.response;
                        for (let i in response) {
                            commit("set", {
                                [i]: response[i]
                            });
                        }
                        if (window.resolve) {
                            window.resolve(response);
                        }
                    } else if (xhrObj.status == 403) {
                        alert("Доступ запрещен.");
                        location.href = "/";
                    } else {
                        alert(
                            "Ошибка сервера:\n" +
                            xhrObj.status +
                            ' - "' +
                            xhrObj.statusText +
                            '"'
                        );
                    }
                    commit("set", { loader: false });
                },
                false
            );
            xhr.addEventListener(
                "error",
                () => {
                    alert("Ошибка сервера");
                    commit("set", { loader: false });
                },
                false
            );
        },
        clear({ commit }, key) {
            commit("set", {
                [key]: null
            });
        },
        set({ commit }, obj) {
            commit("set", obj);
        },
    }
});