let api = function(root, data, path, resolve) {
    data.set('token', root.$store.state.token);
    root.$store.dispatch('set', { loader: true });
    fetch(root.$store.state.host + path, {
        method: 'POST',
        body: data
    }).then(response => {
        return response.json();
    }).then(response => {
        for (let i in response) {
            if (i in root) {
                root[i] = response[i];
            }
            if (i in root.$store.state) {
                root.$store.dispatch('set', {
                    [i]: response[i] });
            }
        }
        if (resolve) {
            resolve(response);
        }
        root.$store.dispatch('set', { loader: false });
    });
}

export default api;