<template>
  <div>
    <v-app-bar color="deep-purple accent-4" dark>
      <v-app-bar-nav-icon
        v-if="isAuth"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <h4>volga-taxi</h4>
    </v-app-bar>

    <div v-if="loader" class="progress-container">
      <v-progress-circular
        :size="100"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-navigation-drawer v-if="isAuth" v-model="drawer" absolute left temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <template v-for="(item, key) in items">
            <v-list-item :key="key" @click="toPath(item.path)">
              {{ item.name }}
            </v-list-item>
          </template>
          <hr />
          <v-list-item @click.prevent="logout()">
            <a href="#" @click.prevent>Выйти</a>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "menu-component",
  props: {
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        name: "Главная",
        path: "/",
      },
      {
        name: "Выплаты QIWI",
        path: "/transaction",
      },
      {
        name: "Кураторство",
        path: "/curator",
      },
      // {
      //   name: "Выплаты Агент",
      //   path: "/agents",
      // },
      // {
      //   name: "Заказы",
      //   path: "/orders",
      // },
      // {
      //   name: "Бонусные пакеты",
      //   path: "/packages",
      // },
      
      // {
      //   name: "Потенциальные водители",
      //   path: "/potential",
      // },
      // {
      //   name: "ДТП",
      //   path: "/dtp",
      // },
    ],
  }),
  computed: {
    ...mapState({
      loader: (state) => state.loader,
    }),
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  created() {
    this.setGroupMenu();
  },
  methods: {
    setGroupMenu() {
      let group = null;
      for (let i in this.items) {
        let item = this.items[i];
        if (this.$route.path == item.path) {
          group = Number(i);
        }
      }
      this.group = group;
    },
    toPath(path) {
      if (this.$route.path != path) {
        this.$router.push(path);
      }
    },
    logout() {
      if (
        !confirm(
          'Нажав кнопку - "Выход", при повторном заходе в программу, Вам придется заного внести Ваши данные (телефон, email, номер карты). Вы уверены, что хотите выйти?'
        )
      ) {
        return;
      }
      localStorage.clear();
      location.reload();
    },
  },
};
</script>

<style lang="less">
aside {
  a {
    text-decoration: none;
    color: black !important;
  }
}
.progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .v-progress-linear {
    position: absolute;
    top: 0;
  }
}
</style>