<template>
  <div id="agents">
    <v-container>
      <template v-for="item in items">
        <v-card :key="item.id">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <template v-for="(value, key) in item">
                  <tr v-if="key in columns" :key="key">
                    <td>{{ columns[key] }}</td>
                    <td>{{ getValue(value, key) }}</td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </template>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import api from "../api";

export default {
  name: "Agents",
  data() {
    return {
      items: [],
      columns: {
        date: "Дата",
        count_orders: "Кол-во заказов",
        val: "Вал",
        zp: "Выплата",
        rashod: "Расходы"
      },
    };
  },
  created() {
    this.getAgents();
  },
  methods: {
    api(data, resolve) {
      api(this, data, "", resolve);
    },
    getAgents() {
      let data = new FormData();
      data.set("type", "getAgents");
      this.api(data);
    },
    getValue(value, key) {
      if (~key.indexOf("date")) {
        return moment(value).format("DD.MM.YYYY");
      } else if (~key.indexOf("val") || ~key.indexOf("zp")){
          return Number(value).toLocaleString();
      }
      return value;
    },
  },
};
</script>

<style lang="less">
#agents {
  background-color: #eee;
  .v-card {
    margin-bottom: 15px;
  }
  table {
    tr {
      td:first-child {
        width: 200px;
      }
    }
  }
}
</style>